import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import {
  AuthLayoutComponent,
  MainLayoutComponent,
  Page403Component,
  Page404Component,
  Page500Component,
} from "src/core/components";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import("src/app").then((m) => m.AppModule),
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("src/core/modules").then((m) => m.AuthenticationModule),
  },
  { path: "server-error", component: Page500Component },
  { path: "forbidden", component: Page403Component },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
