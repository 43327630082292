import { RouteInfo } from "../components/layout/main-layout/sidebar/sidebar.metadata";
import { TokenUser } from "./token-user";
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard/main",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return true;
    },
    submenu: [],
  },
  {
    path: "",
    title: "Admin",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1;
    },
    submenu: [],
  },
  {
    path: "ApiSetting",
    title: "API setting",
    moduleName: "ApiSettingModule",
    iconType: "material-icons-two-tone",
    icon: "filter_9_plus",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1;
    },
    submenu: [],
  },
  {
    path: "",
    title: "Before sales",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "filter-products",
    title: "Filter Products",
    moduleName: "FilterProductsModule",
    iconType: "material-icons-two-tone",
    icon: "filter_9_plus",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  // {
  //   path: "edit-products-from-csv",
  //   title: "Edit Products From Csv",
  //   moduleName: "EditProductsFromCsvModule",
  //   iconType: "material-icons-two-tone",
  //   icon: "description",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   shouldDisplay: (user: TokenUser) => {
  //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //   },
  //   submenu: [],
  // },
  {
    path: "filter-products-csv",
    title: "Filter Products Csv",
    moduleName: "FilterProductsCsvModule",
    iconType: "material-icons-two-tone",
    icon: "view_module",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    shouldDisplay: (user: TokenUser) => {
      return true;
    },
    badgeClass: "",
    submenu: [
      // {
      //   path: "filter-products-csv/filter-woo",
      //   title: "Filter Products Woocommerce",
      //   moduleName: "FilterProductsCsvModule",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   shouldDisplay: (user: TokenUser) => {
      //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
      //   },
      //   badgeClass: "",
      //   submenu: [],
      // },
      {
        path: "filter-products-csv/filter-csv-woo",
        title: "Filter Csv (Woo Format)",
        moduleName: "FilterProductsCsvModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        shouldDisplay: (user: TokenUser) => {
          return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
        },
        badgeClass: "",
        submenu: [],
      },
      {
        path: "filter-products-csv/filter-csv-spf",
        title: "Filter Csv (SPF Format)",
        moduleName: "FilterProductsCsvModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        shouldDisplay: (user: TokenUser) => {
          return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
        },
        badgeClass: "",
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Products",
  //   moduleName: "product",
  //   iconType: "material-icons-two-tone",
  //   icon: "view_module",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   shouldDisplay: (user: TokenUser) => {
  //     return true;
  //   },
  //   badgeClass: "",
  //   submenu: [
  //     {
  //       path: "/products/all-product",
  //       title: "All Products",
  //       moduleName: "product",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       shouldDisplay: (user: TokenUser) => {
  //         return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //       },
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //     {
  //       path: "/products/bulk-update",
  //       title: "Bulk Update",
  //       moduleName: "product",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       shouldDisplay: (user: TokenUser) => {
  //         return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //       },
  //       badgeClass: "",
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "collections",
  //   title: "Collections",
  //   moduleName: "collections",
  //   iconType: "material-icons-two-tone",
  //   icon: "filter_9_plus",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   shouldDisplay: (user: TokenUser) => {
  //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //   },
  //   submenu: [],
  // },
  // {
  //   path: "/stores",
  //   title: "Stores",
  //   moduleName: "store",
  //   iconType: "material-icons-two-tone",
  //   icon: "store",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   shouldDisplay: (user: TokenUser) => {
  //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //   },
  //   submenu: [
  //     {
  //       path: "/stores/sbstores",
  //       title: "Shopbase Stores",
  //       moduleName: "product",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       shouldDisplay: (user: TokenUser) => {
  //         return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //       },
  //       submenu: [],
  //     },
  //     {
  //       path: "/stores/woostores",
  //       title: "Woocommerce Store",
  //       moduleName: "product",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       shouldDisplay: (user: TokenUser) => {
  //         return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //       },
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "/crawlers",
  //   title: "Crawler",
  //   moduleName: "crawler",
  //   iconType: "material-icons-two-tone",
  //   icon: "cloud_download",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   shouldDisplay: (user: TokenUser) => {
  //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //   },
  //   submenu: [],
  // },
  {
    path: "/gencsv",
    title: "Gen Product Csv",
    moduleName: "gencsv",
    iconType: "material-icons-two-tone",
    icon: "text_snippet",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "/updateAttribute",
    title: "Update Product Attr",
    moduleName: "updateattr",
    iconType: "material-icons-two-tone",
    icon: "text_snippet",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "/updatemeta",
    title: "Bulk Update MetaData",
    moduleName: "updateattr",
    iconType: "material-icons-two-tone",
    icon: "text_snippet",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "",
    title: "After Sales",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "orders",
    title: "Orders",
    moduleName: "collections",
    iconType: "material-icons-two-tone",
    icon: "shopping_cart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "analytic",
    title: "Analytic",
    moduleName: "collections",
    iconType: "material-icons-two-tone",
    icon: "bar_chart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("_admin_") !== -1;
    },
    submenu: [],
  },
  {
    path: "payment",
    title: "Payment",
    moduleName: "collections",
    iconType: "material-icons-two-tone",
    icon: "payment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "disputes-from-partner",
    title: "Disputes Partner",
    moduleName: "collections",
    iconType: "material-icons-two-tone",
    icon: "offline_bolt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "disputes",
    title: "Disputes",
    moduleName: "collections",
    iconType: "material-icons-two-tone",
    icon: "offline_bolt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [],
  },
  {
    path: "/fulfillment",
    title: "Fulfillment",
    moduleName: "collections",
    iconType: "material-icons-two-tone",
    icon: "store",
    // class: "menu-toggle",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
    },
    submenu: [
      // {
      //   path: "/fullfillments/printify",
      //   title: "Printify",
      //   moduleName: "product",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   submenu: [],
      //   shouldDisplay: (user: TokenUser) => {
      //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
      //   },
      // },
      // {
      //   path: "/fullfillments/buggerprint",
      //   title: "Bugger Print",
      //   moduleName: "product",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   submenu: [],
      //   shouldDisplay: (user: TokenUser) => {
      //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
      //   },
      // },
    ],
  },
  // {
  //   path: "/designs",
  //   title: "Designs",
  //   moduleName: "collections",
  //   iconType: "material-icons-two-tone",
  //   icon: "collections",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   shouldDisplay: (user: TokenUser) => {
  //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //   },
  //   submenu: [],
  // },
  // {
  //   path: "/customer-services",
  //   title: "Customer Service",
  //   moduleName: "customer-service",
  //   iconType: "material-icons-two-tone",
  //   icon: "collections",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   submenu: [],
  //   shouldDisplay: (user: TokenUser) => {
  //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //   },
  // },
  // {
  //   path: "",
  //   title: "Administrator",
  //   moduleName: "",
  //   iconType: "",
  //   icon: "",
  //   class: "",
  //   groupTitle: true,
  //   badge: "",
  //   badgeClass: "",
  //   shouldDisplay: (user: TokenUser) => {
  //     return (
  //       user.user_scopes.indexOf("admin") !== -1 | || user.user_scopes.indexOf('manager') !== -1|
  //       user.user_scopes.indexOf("super_admin") !== -1
  //     );
  //   },
  //   submenu: [],
  // },
  // {
  //   path: "/users",
  //   title: "Users Manager",
  //   moduleName: "collections",
  //   iconType: "material-icons-two-tone",
  //   icon: "person_add",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   submenu: [],
  //   shouldDisplay: (user: TokenUser) => {
  //     return user.user_scopes.indexOf("admin") !== -1 || user.user_scopes.indexOf('manager') !== -1;
  //   },
  // },

  {
    path: "/admin/teams",
    title: "Team Manager",
    moduleName: "collections",
    iconType: "material-icons-two-tone",
    icon: "group",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    shouldDisplay: (user: TokenUser) => {
      return (
        user.user_scopes.indexOf("super_admin") !== -1 &&
        user.team_scopes.indexOf("super_admin") !== -1
      );
    },
    submenu: [],
  },
];
