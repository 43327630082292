import { NgModule, Optional, SkipSelf } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreRoutingModule } from "./core-routing.module";
import { CoreComponent } from "./core.component";

import {
  LocationStrategy,
  HashLocationStrategy,
  CommonModule,
} from "@angular/common";
import { ErrorInterceptor, JwtInterceptor } from "./interceptors";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";

import { ClickOutsideModule } from "ng-click-outside";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  MainLayoutComponent,
  AuthLayoutComponent,
  HeaderComponent,
  PageLoaderComponent,
  RightSidebarComponent,
  SidebarComponent,
} from "./components";
import {
  AuthService,
  DynamicScriptLoaderService,
  RightSidebarService,
} from "./services";
import { AuthGuard, LoggedInAuthGuard, throwIfAlreadyLoaded } from "./guards";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FakeBackendProvider } from '../app/interceptors/fake-backend.interceptor';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  declarations: [
    CoreComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    NgbModule,
    NgxSpinnerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    RightSidebarService,
    AuthGuard,
    LoggedInAuthGuard,
    AuthService,
    DynamicScriptLoaderService,
    FakeBackendProvider,
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
