export interface ProductInterface {
    id: number;
    title: string;
    vendor: string;
    type: string;
    image: string;
    price: number;
}
export const PRODUCT_DATA: ProductInterface[] = [
{
    id: 1,
    image: "https://placeimg.com/380/380/fashion",
    title: "male",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 2,
    image: "https://placeimg.com/380/380/fashion",
    title: "female",
    vendor: "Gucci",
    type: "hoodie",
    price: 44.49,
},
{
    id: 3,
    image: "https://placeimg.com/380/380/fashion",
    title: "female",
    vendor: "Gucci",
    type: "hoodie",
    price: 44.49,
},
{
    id: 4,
    image: "https://placeimg.com/380/380/fashion",
    title: "male",
    vendor: "Gucci",
    type: "hoodie",
    price: 44.49,
},
{
    id: 5,
    image: "https://placeimg.com/380/380/fashion",
    title: "female",
    vendor: "Gucci",
    type: "hoodie",
    price: 44.49,
},
{
    id: 6,
    image: "https://placeimg.com/380/380/fashion",
    title: "abc",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 7,
    image: "https://placeimg.com/380/380/fashion",
    title: "def",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 8,
    image: "https://placeimg.com/380/380/fashion",
    title: "abc",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 9,
    image: "https://placeimg.com/380/380/fashion",
    title: "zzz",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 10,
    image: "https://placeimg.com/380/380/fashion",
    title: "ccc",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 11,
    image: "https://placeimg.com/380/380/fashion",
    title: "bbb",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 12,
    image: "https://placeimg.com/380/380/fashion",
    title: "eee",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 13,
    image: "https://placeimg.com/380/380/fashion",
    title: "fff",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
{
    id: 14,
    image: "https://placeimg.com/380/380/fashion",
    title: "aaa",
    vendor: "Gucci",
    type: "tee",
    price: 24.49,
},
];
