export interface CollectionDataModel {
    id: number;
    title: string;
    rules: {
        title_include: string[];
        title_not_include: string[];
        vendors: string[];
        types: string[];
        tags: string[];
    }
}

export const COLLECTION_DATA: CollectionDataModel[] = [
    {
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 2,
        title: 'Collection 2',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 3,
        title: 'Collection 3',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 4,
        title: 'Collection 4',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 5,
        title: 'Collection 5',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },{
        id: 1,
        title: 'Collection 1',
        rules: {
            title_include: [
                "Containt this keyword",
                "And this keyword"
            ],
            title_not_include: [
                "Not containt this keyword",
                "And this keyword"
            ],
            vendors: [
                "Gucci",
                "LV",
                "Lacoste"
            ],
            types: [
                "T-Shirts"
            ],
            tags: [
                "2D",
                "Has Artword"
            ],
        }
    },
]
