export class TokenUser {
  id: number;
  access_token: string;
  avatar: string;
  fullname: string;
  settings: string;
  user_scopes: string[];
  team_scopes: string[];
  user: string;
  enName: string;
  trelloMemberId: string;
}
