import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/core/services";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { timer, interval, firstValueFrom } from 'rxjs';
import {teleBotId, teleGroupIds } from '../../../../environments/environment';
@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = "";
  hide = true;
  loading: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient
  ) { }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  sendMess(botId, groupId, content) {
    this.http.get(`https://api.telegram.org/bot${botId}/sendMessage?chat_id=${groupId}&parse_mode=html&text=${content}`).subscribe(res => {
      // console.log(res)
    });
  }
  async onSubmit() {
    this.submitted = true;
    this.error = "";
    this.loading = true;
    let rndInt = this.randomIntFromInterval(800, 2000);
    const source$ = interval(rndInt);
    const firstNumber = await firstValueFrom(source$);
    this.loading = false;
    if (this.loginForm.invalid) {
      this.error = "Username and Password not valid !";
      return;
    } else {
      this.authService
        .login(this.f.username.value, this.f.password.value)
        .subscribe(
          (res) => {
            if (res) {
              const token = this.authService.currentUserValue.access_token;
              if (token) {
                this.sendMess(teleBotId, teleGroupIds.group3ae, `<b>${this.f.username.value}</b> logged in`);
                this.router.navigate(["/dashboard/main"]);
              }
            } else {
              this.error = "Invalid Login";
              this.sendMess(teleBotId, teleGroupIds.group3ae, `<b>${this.f.username.value}</b> failed login`);
            }
          },
          (error) => {
            console.error(error);
            this.error = error;
            this.submitted = false;
            this.sendMess(teleBotId, teleGroupIds.group3ae, `<b>${this.f.username.value}</b> failed login`);
          }
        );
    }
  }
  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
