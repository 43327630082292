<section>
  <h1>PAGE NOT FOUND</h1>
  <p class="zoom-area">The Page You Are Looking For Not Available!</p>
  <section class="error-container">
    <span class="four"><span class="screen-reader-text">4</span></span>
    <span class="zero"><span class="screen-reader-text">0</span></span>
    <span class="four"><span class="screen-reader-text">4</span></span>
  </section>
  <div class="link-container">
    <a target="_blank" href="/" class="more-link">Go To Home Page</a>
  </div>
</section>