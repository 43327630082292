import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    public router: Router
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          // this.authenticationService.logout();
          // location.reload();
        } else if (err.status == 403) {
          // this.router.navigateByUrl("/unauthorized");
        }
        console.log(err);
        let error = "Invalid credentials";
        if (
          err.error !== undefined &&
          err.error.data !== undefined &&
          err.error.data.message !== undefined
        ) {
          error = err.error.data.message;
        }
        const thrError = new Error(error);
        thrError.name = err.statusText;
        return throwError(thrError);
      })
    );
  }
}
